@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-VariableFont_wdth,wght.ttf');
}

@font-face {
  font-family: 'Norwester';
  src: url('./fonts/norwester.ttf');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

*::selection {
  background-color: #3032dc;
  color: #fefefe;
}

/* scroll bar */
body::-webkit-scrollbar-track {
  background-color: #c4c4c4;
  border-radius: 4px;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

body::-webkit-scrollbar-thumb:horizontal {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #c4c4c4;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:horizontal {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-shadows {
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.custom-loader {
  background: linear-gradient(to left, #2e2d6a 50%, #26ca99 50%) right;
  background-size: 205%;
}

.custom-loader-fill {
  background-position: left;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 0s 600000s,
    color 0s 600000s !important;
}
